.agents-containter {
    width: 100%;
    padding: 2% 6.5%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
}

.agents-containter div:nth-child(n) {
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
}

.agents-containter div:nth-child(2) {
    margin-top: 3%;
    cursor: pointer;
}
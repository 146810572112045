.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: row;
  align-content: flex-start;
}

.app-container .sidebar{
  order: 0;
  flex: 1 0 auto;
  align-self: auto;
  /*height: 100%;*/
  background: #F3F4F6;
  border-right: 1px solid #E5E7EB;
  position: relative;
  /* width: 15.8%; */
}

.agents-containter{
   z-index: 99999;
}

.app-container .chat{
  order: 0;
  flex-basis: 86.5%;
  align-self: auto;

  display:flex;
  flex-direction: column;
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chat-window {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-outer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
  justify-content: center;
  align-content: center;
  top: 10px;
}

.chat-input {
  grid-column: span 3;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.agent-name {
  text-align: center;
  margin-top: -15px;
  color: rgba(0, 0, 0, 0.6);
}

.small-image {
  width: 50px;
  height: 50px;
  float: right;
}

.agent-name-small {
  font-size: 12px;
  float: right;
}

.small-image-container {
  text-align: right;
}

.agent-small {
  margin-left: 80%;
}

.message {
  background-color: #e0f2f1;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  max-width: 100%;
}

.bubble {
  background-color: #e0f2f1;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
}

.chat-box-header {
  text-align: center;
}

.chat-box-header img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
}

.message-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.agent-info {
  text-align: right;
}

.fade-out {
  opacity: 0.5;
  transition: opacity 0.5s;
}

.footer {
  position: relative;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  background-color: white;
  flex: 0 1 auto;
}



.messages {
  margin-left: 12%;
  width: 80%
}

.messages-container {
  margin-top: 12%;
}

.chat-icon {
  max-width: 12%; 
  position: absolute;
  top: 0; 
  left: 0;
}

.chat-container {
  position: relative
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.chat-box.chat.\34 .css-3su884-MuiPaper-root {
  height: 78vh;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.chat-box.chat.\38 .css-3su884-MuiPaper-root {
  height: 78vh;
}
.container {
  display: contents;
  flex-direction: column;
  min-height: 100vh;
  max-height: 400px;
}

@media (max-width: 767px) {
  .container {
    display:flex;
    flex-direction: column;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.chat-box.chat.\34 .css-3su884-MuiPaper-root {
    height: 35vh;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation3.chat-box.chat.\38 .css-3su884-MuiPaper-root {
    height: 35vh;
  }
}

@media  (max-width:1024px){
  .app-container .sidebar {
    order: 0;
    flex-basis: 0;
  }

  .app-container .chat {
    order: 0;
    flex-basis: 100%;
  }
}

.comparison-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-section {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}

.chat-column {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.message-bubble {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-section {
  display: flex;
  padding: 10px;
  align-items: center;
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background: none;
  /* width: 15.8%; */
}

.compare-container {
  display: flex;
  flex-direction: column;
  flex: 0.5 0 auto;/*1 0 auto;*/
  padding: 0 0.5%;
}

.amplify-button.amplify-field-group__control.amplify-button--primary {
  background-color: black !important;
  border-color: black !important;
}

/* Cambia el color al pasar el mouse */
.amplify-button.amplify-field-group__control.amplify-button--primary:hover {
  background-color: #555555 !important;
  border-color: #555555 !important;
}

.amplify-button.amplify-field-group__control.amplify-button--link{
  color: #000000 !important;
}

.amplify-button.amplify-field-group__control.amplify-button--link:hover{
  background-color: #F3F4F6 !important;
}